var prodcat = prodcat || {};
var site = site || {};

site.direction = site.direction || {};
(function ($) {
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $thisProd = $(this);
    var $prodImage = $thisProd.find('.js-product-brief-image');
    var $prodImageAlt = $thisProd.find('.js-product-brief-image-alt');
    var $prodAddToBag = $thisProd.find('.js-add-to-cart').not('.js-use-with-atb');
    var $sppLink = $thisProd.find('.js-spp-link');
    var $shadesCarousel = $thisProd.find('.js-filter-swatch-slider').length
      ? $thisProd.find('.js-filter-swatch-slider')
      : $thisProd.find('.js-product-brief-shades__grid');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      var sppHref = $sppLink.attr('href'); // new version of prodLink
      var skuBadge = skuData.hasOwnProperty('MISC_FLAG_TEXT') ? skuData.MISC_FLAG_TEXT : '';
      var shadeName = skuData.hasOwnProperty('SHADENAME') ? skuData.SHADENAME : '';
      var shadeDescription = skuData.hasOwnProperty('SHADE_DESCRIPTION')
        ? skuData.SHADE_DESCRIPTION
        : '';
      var shadeRouteName = prodcat.ui.generateShadePath(skuBaseId);
      var priceSku = skuData.PRICE;
      var skuColor = skuData.HEX_VALUE_STRING;

      if ($shadesCarousel.hasClass('slick-initialized') && !$shadesCarousel.hasClass('hidden')) {
        productBriefCarouselSkuSelect($shadesCarousel, skuBaseId);
      }

      // Update this product
      $thisProd.attr('data-sku-base-id', skuBaseId);
      updateProdImage($prodImage, $prodImageAlt, skuData);
      updateProdImageSrcsets($prodImage, $prodImageAlt, skuData);

      // updating add to bag button
      $prodAddToBag.attr('data-sku-base-id', skuBaseId);

      if (typeof sppHref !== 'undefined') {
        if (shadeName) {
          var sppHrefSplit = sppHref.split('#');

          sppHref = sppHrefSplit[0] + shadeRouteName;
          $sppLink.attr('href', sppHref);
        }
      }

      // updating sku level badge
      if (!!skuBadge) {
        $thisProd.find('.js-product-brief-shades__badge').html(skuBadge);
      } else {
        $thisProd.find('.js-product-brief-shades__badge').html('');
      }
      // updating shadename
      $thisProd.find('.js-product-brief-shades__name').html(shadeName);
      $thisProd.find('.js-product-brief-shades__description').html(shadeDescription);
      var $shadeDot = $('.js-product-brief__shade-dot', $thisProd);
      if ($shadeDot.length > 0) {
        $shadeDot.css('background-color', skuColor);
      }
      $prodAddToBag.attr('data-sku-base-id', skuBaseId);
      $thisProd.attr('data-price-sort', priceSku);
    }
  });

  $(document).on('product.init', '.js-product', function () {
    var $thisProd = $(this);
    var $productBrief = $thisProd.find('.js-product-brief-v3');
    var $altImage = $thisProd.find('.js-product-brief__image-wrapper');
    var $prodImage = $thisProd.find('.js-product-brief-image');
    var $prodImageAlt = $thisProd.find('.js-product-brief-image-alt');
    var skuBaseId = $thisProd.attr('data-sku-base-id');
    var skuData = null;

    if ($productBrief.length < 1) {
      return;
    }

    Drupal.behaviors.productBriefV3.initAltImageCarousel($altImage);
    if (!!skuBaseId) {
      skuData = prodcat.data.getSku(skuBaseId);
      $thisProd.trigger('product.skuSelect', [skuBaseId]);
    }
    if (!!skuData) {
      updateProdImageSrcsets($prodImage, $prodImageAlt, skuData);
    }
  });

  function productBriefCarouselSkuSelect($shadesCarousel, skuBaseId) {
    var $clonedParent = $();
    var $slide = $shadesCarousel.find('div[data-sku-base-id="' + skuBaseId + '"]');
    var slideNo = $slide.attr('index') || 0;

    $shadesCarousel.attr('data-initial-slide', slideNo).data('initialSlide', parseInt(slideNo, 10));
    $clonedParent = $shadesCarousel.closest('.slick-slide.slick-cloned');
    if ($clonedParent.length === 0) {
      var slidesCount = $shadesCarousel.attr('data-slides-count');
      $shadesCarousel.slickGoTo(parseInt(slideNo / slidesCount + 1) * slidesCount - slidesCount);
    }
  }

  function updateProdImage($prodImage, $prodImageAlt, skuData) {
    if ($prodImage.hasClass('lazyload')) {
      // if the image was not loaded yet but has lazyload we need to update only data-src lazyload will set the src
      if (
        !!skuData.IMAGE_MEDIUM_COMBINED &&
        skuData.IMAGE_MEDIUM_COMBINED[0] !== $prodImage.attr('data-src')
      ) {
        $prodImage.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
        $prodImageAlt.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[1]);
      }
    } else {
      // if the image was already loaded by lazyload or lazyload is not used on this image
      // we need to update both data-src and src
      if (
        !!skuData.IMAGE_MEDIUM_COMBINED &&
        skuData.IMAGE_MEDIUM_COMBINED[0] !== $prodImage.attr('src')
      ) {
        $prodImage
          .attr('src', skuData.IMAGE_MEDIUM_COMBINED[0])
          .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
        $prodImageAlt
          .attr('src', skuData.IMAGE_MEDIUM_COMBINED[1])
          .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[1]);
      }
    }
  }

  function updateProdImageSrcsets($prodImage, $prodImageAlt, skuData) {
    var isProductImageResponsive =
      Drupal.settings.global_variables &&
      Drupal.settings.global_variables.product_responsive_images;
    var baseImagePath;
    var baseImageAltPath;
    var imageSrcset;
    var imageAltSrcset;
    var productBriefImageSrcsetSizes = [
      {
        size: '310',
        xDescriptor: '310w'
      },
      {
        size: '768',
        xDescriptor: '768w'
      }
    ];

    if (isProductImageResponsive) {
      baseImagePath =
        skuData.IMAGE_MEDIUM_COMBINED[0] && skuData.IMAGE_MEDIUM_COMBINED[0].split('?')[0];
      baseImageAltPath =
        skuData.IMAGE_MEDIUM_COMBINED[1] && skuData.IMAGE_MEDIUM_COMBINED[1].split('?')[0];
      imageSrcset = '';
      imageAltSrcset = '';
      productBriefImageSrcsetSizes.forEach((imageSrcsetSize) => {
        imageSrcset += `${baseImagePath}?width=${imageSrcsetSize.size}&height=${imageSrcsetSize.size} ${imageSrcsetSize.xDescriptor},`;
        imageAltSrcset += `${baseImageAltPath}?width=${imageSrcsetSize.size}&height=${imageSrcsetSize.size} ${imageSrcsetSize.xDescriptor},`;
      });
      imageSrcset = imageSrcset.slice(0, -1);
      imageAltSrcset = imageAltSrcset.slice(0, -1);

      if ($prodImage.hasClass('lazyload')) {
        $prodImage.attr('data-srcset', imageSrcset);
        $prodImageAlt.attr('data-srcset', imageAltSrcset);
      } else {
        $prodImage.attr('srcset', imageSrcset).attr('data-srcset', imageSrcset);
        $prodImageAlt.attr('srcset', imageAltSrcset).attr('data-srcset', imageAltSrcset);
      }
    }
  }

  Drupal.behaviors.productBriefV3 = {
    isMobile: false,
    attach: function (context) {
      var self = this;
      var $productBrief = $('.js-product-brief-v3', context);
      var $container = $('.js-product-brief__image-wrapper', $productBrief);
      var config = {
        childList: true,
        subtree: true
      };

      if (
        navigator.userAgent.indexOf('MSIE ') !== -1 ||
        navigator.userAgent.indexOf('Trident/') !== -1
      ) {
        $productBrief.once('IE-min-height');
      }
      // Observable for reviews snippet text to be updated.
      var observer = new MutationObserver(function (mutations, observer) {
        mutations.forEach(function (mutation) {
          var $addedNodes = $(mutation.addedNodes);

          if ($addedNodes.length > 0) {
            $addedNodes.find('.pr-category-snippet__total').each(function () {
              var $string = $(this);
              var $ratings = $string.prev('.pr-category-snippet__rating');
              var $product = $ratings.parents('.js-product-brief');
              var $prSnippetRating = $('.pr-snippet-rating-decimal', $ratings);
              var reviewsText = $string.text().replace(/\D/g, '');
              var noReviewsText = '';

              if (reviewsText.length > 0) {
                $string.text('(' + reviewsText + ')');
              } else {
                noReviewsText =
                  site.translations.product.product_pr_no_reviews || 'Be the First to Review';
                $string.text(noReviewsText);
                $ratings.hide();
              }
              if ($prSnippetRating.length > 0) {
                $product.attr('data-rating', $prSnippetRating.text());
              }
            });
          }
        });
      });

      $('.review-snippet', $productBrief).each(function () {
        observer.observe($(this)[0], config);
      });

      self.isMobile = Unison.fetch.now().name === 'small';
      self.initAltImageCarousel($container);
      Unison.on('change', function () {
        self.isMobile = Unison.fetch.now().name === 'small';
        if (self.isMobile) {
          self.initAltImageCarousel($container);
        } else {
          if ($container.hasClass('slick-initialized')) {
            $container.unslick();
          }
        }
      });

      $($productBrief).each(function () {
        self.initShadesOverlay($(this));
        self.initSizedOverlay($(this));
      });
    },
    initAltImageCarousel: function ($container) {
      var $productGridCarousel = $container.closest('.js-product-carousel');
      var settings = {
        rtl: site.direction.isRTL,
        infinite: false,
        mobileFirst: true,
        arrows: false,
        swipe: $productGridCarousel.length > 0 ? false : true,
        dots: true,
        speed: 300,
        slide: '.js-product-brief-image-link',
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand'
      };
      var self = this;

      // Init this carousel with our settings
      if (self.isMobile) {
        $container.not('.slick-initialized').slick(settings);
      }
    },
    initShadesOverlay: function ($productBrief) {
      var $shadesOverlay = $('.js-product-brief__shades-overlay', $productBrief);
      var $shadesOverlayTrigger = $('.js-product-brief__shades-overlay-trigger', $productBrief);
      var $shadesOverlayCloseCta = $('.js-product-brief__shades-overlay-close', $shadesOverlay);
      var $shadesOverlayCartCta = $('.js-product-cta, .js-notify-me-alert', $shadesOverlay);

      if ($shadesOverlayTrigger.length > 0 && $shadesOverlay.length > 0) {
        $shadesOverlayTrigger.once().on('click', function (event) {
          var $product = $(event.target).closest('.js-product');
          var productId = $product.data('product-id');
          var $shadesOverlay = $product.find('.js-product-brief__shades-overlay');
          var $shadesOverlayClone = $shadesOverlay.clone(true).addClass('active');
          var $swatchList = $('.js-filter-swatch-slider', $shadesOverlayClone).length
            ? $('.js-filter-swatch-slider', $shadesOverlayClone)
            : $('.js-product-brief-shades__grid', $shadesOverlayClone);
          var $shadesOverlayContainer = $('<div />')
            .addClass('js-product')
            .attr('data-product-id', productId);
          var options = {
            content: $shadesOverlayContainer.append($shadesOverlayClone),
            className: 'product-brief__shades-overlay__options',
            bottom: 0,
            fixed: 'true',
            maxHeight: $shadesOverlay.outerHeight(true),
            closeButton: false,
            hideOnClickOutside: true,
            onComplete: function () {
              Drupal.behaviors.productBriefShadesV2.reinitShadeCarousel($swatchList);
              $('html').addClass('overlay-open');
            },
            onClosed: function () {
              $('html').removeClass('overlay-open');
            }
          };

          generic.overlay.launch(options);
          Drupal.behaviors.productBriefShadesV2.reinitShadeCarousel($swatchList);
        });

        $shadesOverlayCloseCta.once().on('click', function (event) {
          event.preventDefault();
          generic.overlay.hide();
        });
        $shadesOverlayCartCta.once().on('click', function () {
          generic.overlay.hide();
        });
      }
    },
    initSizedOverlay: function ($productBrief) {
      var $sizedOverlay = $('.js-product-brief__sized-overlay', $productBrief);
      var $sizedOverlayTrigger = $('.js-product-brief__sized-overlay-trigger', $productBrief);
      var $sizedOverlayCloseCta = $('.js-product-brief__sized-overlay-close', $sizedOverlay);
      var $sizedOverlayCartCta = $('.js-product-cta, .js-notify-me-alert', $sizedOverlay);

      if ($sizedOverlayTrigger.length > 0 && $sizedOverlay.length > 0) {
        $sizedOverlayTrigger.once().on('click', function (event) {
          var $product = $(event.target).closest('.js-product');
          var productId = $product.data('product-id');
          var $sizedOverlay = $product.find('.js-product-brief__sized-overlay');
          var $sizedOverlayClone = $sizedOverlay.clone(true).addClass('active');
          var $sizedOverlayContainer = $('<div />')
            .addClass('js-product')
            .attr('data-product-id', productId);
          var options = {
            content: $sizedOverlayContainer.append($sizedOverlayClone),
            className: 'product-brief__sized-overlay__options',
            bottom: 0,
            fixed: 'true',
            maxHeight: $sizedOverlay.outerHeight(true),
            closeButton: false,
            hideOnClickOutside: true,
            onComplete: function () {
              $('html').addClass('overlay-open');
            },
            onClosed: function () {
              $('html').removeClass('overlay-open');
            }
          };

          generic.overlay.launch(options);
        });

        $sizedOverlayCloseCta.once().on('click', function (event) {
          event.preventDefault();
          generic.overlay.hide();
        });
        $sizedOverlayCartCta.once().on('click', function () {
          generic.overlay.hide();
        });
      }
    }
  };
})(jQuery);
